import React from 'react';
import notFound from '@/assets/not-found.jpeg';

const NotFound = () => {
  return (
    <div className="no-data" style={{width:"100%",height:"100%"}}>
      <img style={{width:"100%",height:"auto"}} src={notFound} alt="no-data" />
    </div>
  );
};
export default NotFound;

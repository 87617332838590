/**
 * 时间日期格式化
 * 用法 formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss')
 * @param  time
 * @param  fmt
 */
const formatTime = (time, fmt = "yyyy-MM-dd hh:mm") => {
  time = parseInt(time);
  if (!time) {
    return "";
  }
  const date = new Date(time);
  let o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

const compareToMonthEnd = (timestamp) => {
  try {
    // 将传入的时间戳字符串转换为 Date 对象
    const inputDate = new Date(
      (timestamp || "").slice(0, 4),
      parseInt((timestamp || "").slice(4, 6)) - 1,
      (timestamp || "").slice(6, 8),
      (timestamp || "").slice(8, 10),
      (timestamp || "").slice(10, 12),
      (timestamp || "").slice(12, 14)
    );

    // 获取本月底最后一天的日期
    const lastDayOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth() + 1,
      0
    );

    // 获取当前时间
    const currentTime = new Date();
    console.log(currentTime);

    // 比较当前时间和本月底最后一天的时间戳
    if (currentTime > lastDayOfMonth) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

const parseUrlParams = (url) => {
  let params = null;

  const queryString = url.split("?")[1];
  if (queryString) {
    params = {};
    const keyValuePairs = queryString.split("&");
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      params[key] = value;
    });
  }
  return params;
};

// // 示例用法
// const (timestamp || "") = "20231207161500";
// const result = compareToMonthEnd((timestamp || ""));
// console.log(result);

/**
 * 数字转成 k、w 方式
 * @param  num
 */
const formatNumber = (num) => {
  return num >= 1e3 && num < 1e4
    ? (num / 1e3).toFixed(1) + "k"
    : num >= 1e4
    ? (num / 1e4).toFixed(1) + "w"
    : num;
};

const getMonth = (timestamp) => {
  let startMonth = timestamp.substr(0, 6);
  let date = new Date();
  let getmonth =
    date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0");
  return startMonth === getmonth;
};

export {
  formatTime,
  formatNumber,
  compareToMonthEnd,
  parseUrlParams,
  getMonth,
};

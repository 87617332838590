import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import "lib-flexible";
import AppRouter from "./router";
import store from "./redux/store";
import { Provider } from "react-redux";
import { parseUrlParams } from "@/utils/filter";
let url =
  window.location.href;
//   "http://huodonggexinggcailing-h5.hzwochi.com/index.html?param1=13073631202&orderId=1240704151202188330";
// let urlData = parseUrlParams(url);
// if (
//   urlData &&
//   Object.keys(urlData).length > 0 &&
//   urlData.param1 &&
//   urlData.param1 !== ""
// ) {
//   window.localStorage.setItem("tel", urlData.param1);
//   window.localStorage.setItem("phone", urlData.param1);
// }

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
